import React from 'react';

import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import PLCForm from '../../components/PLCForm.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import PLCData from './PLCData/index.jsx';
import ReadableCurrency from './ReadableCurrency.jsx';

function PLCRow({
  // Props
  plc,
  setTableReload,
}) {
  const { editMode } = React.useContext(PageContext);

  //---------------------------------------------------------------------------
  // States
  //---------------------------------------------------------------------------
  const [expanded, setExpanded] = React.useState(false);
  const ref = React.useRef(null);

  //---------------------------------------------------------------------------
  // Callback Functions
  //---------------------------------------------------------------------------
  const handleExpandClick = React.useCallback(() => {
    setExpanded((previous) => !previous);
  }, [setExpanded]);

  React.useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        const y = ref.current?.getBoundingClientRect().top;
        if (y) {
          window.scrollTo({ top: y + window.scrollY - 70, behavior: 'smooth' });
        }
      }, 300);
    }
  }, [expanded]);

  const progressPercent = React.useMemo(
    () => (100 * plc.completedTasks) / plc.totalTasks,
    [plc.completedTasks, plc.totalTasks]
  );
  const progressColor = React.useMemo(() => {
    if (progressPercent === 100) {
      return 'success';
    }
    if (progressPercent > 60) {
      return 'primary';
    }
    if (progressPercent > 30) {
      return 'warning';
    }
    return 'error';
  }, [progressPercent]);

  const launchDate = React.useMemo(() => {
    if (plc.isComplete && plc.dateOfCompletion) {
      return `Completed ${plc.dateOfCompletion}`;
    }
    return plc.launchDate;
  }, [plc.dateOfCompletion, plc.isComplete, plc.launchDate]);

  return (
    <Card square ref={ref} sx={{ my: 0.5, ...(expanded && { my: 1, boxShadow: 6 }) }}>
      <Grid
        container
        spacing={1}
        onClick={handleExpandClick}
        sx={(theme) => ({
          alignItems: 'center',
          p: '0.5rem',
          minHeight: '3.5rem',
          ...(plc.isComplete && { color: theme.palette.text.disabled }),
          ...(expanded && { fontWeight: 'bold' }),
        })}
      >
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size="auto">
          {editMode ? (
            <PLCForm plc={plc} setTableReload={setTableReload} />
          ) : (
            <RocketLaunchIcon sx={{ m: 1 }} />
          )}
        </Grid>
        <Grid size="grow">{plc.name}</Grid>
        <Grid size="grow">
          {plc?.parts?.map((part) => (
            <Chip key={part.id} label={part.number} sx={{ m: 0.5 }} />
          ))}
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={{ sm: 2, md: 1.5 }}>
          <Tooltip
            arrow
            title={`${plc.completedTasks} tasks have been completed out of a total of ${plc.totalTasks} tasks.`}
          >
            <Box sx={{ mt: 1, position: 'relative', display: 'inline-flex' }}>
              <CircularProgress
                variant="determinate"
                value={progressPercent}
                color={progressColor}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
                  {plc.completedTasks}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} size={2}>
          {plc.engineeringRelease}
        </Grid>
        <Grid
          size={{
            xs: 4,
            sm: 2,
          }}
        >
          {launchDate}
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={2}>
          <ReadableCurrency value={plc.thirdYearRevenue} documentNumber={plc.icrDocumentNumber} />
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" sx={{ my: 1, mx: -1 }} />
        <PLCData plc={plc} setTableReload={setTableReload} />
      </Collapse>
    </Card>
  );
}

export default PLCRow;
