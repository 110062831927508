import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';

import { taskNames } from '../../shared/taskList.js';
import axios from '../axiosClient.js';
import useAuth0Roles from '../hooks/auth0Roles.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormAutocompleteInput from './common/FormAutocompleteInput.jsx';
import FormStringInput from './common/FormStringInput.jsx';
import ConfirmAction from './ConfirmAction.jsx';

const trackedDocumentOptions = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
];

function TaskForm({
  // Props
  task,
  plcId,
  setTableReload,
}) {
  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);

  const formTitle = React.useMemo(
    () => (task?.name ? 'Update PLC Task' : 'Add PLC Task'),
    [task?.name]
  );
  const disablePropagation = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(
    () => ({
      name: task?.name || '',
      trackedDocument:
        trackedDocumentOptions?.find((option) => option.id === `${task?.trackedDocument}`) || '',
      section: task?.section || '',
      order: task?.order || '',
    }),
    [task?.name, task?.order, task?.section, task?.trackedDocument]
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  const { isDirty } = useFormState({ control });

  const onSubmit = React.useCallback(
    async (data) => {
      // Clean up the form data
      /* eslint-disable no-param-reassign */
      if (data.order !== '') {
        data.order = Number(data.order);
      } else {
        data.order = undefined;
      }
      if (data.documentNumber) {
        data.documentNumber = data.documentNumber?.id;
      }
      data.trackedDocument = data.trackedDocument?.id === 'true';
      data.plcId = plcId;
      /* eslint-enable no-param-reassign */

      // Disable the submit button and display the spinner
      setLoading(true);

      try {
        if (task?.id) {
          const taskUrlComponent = encodeURIComponent(task.id);

          await axios({
            method: 'PATCH',
            url: `/api/plcTasks/${taskUrlComponent}`,
            data,
          });
        } else {
          await axios({
            method: 'POST',
            url: `/api/plcTasks`,
            data,
          });
        }

        // Reset and close the modal
        reset(defaultValues);
        setOpen(false);

        // Update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }

        setSuccess('Successfully saved changes!');
      } catch (err) {
        setError(err?.response?.data?.error || err?.message);
      }
      setLoading(false);
    },
    [plcId, task, reset, defaultValues, setTableReload, setSuccess]
  );

  const handleOpen = React.useCallback((event) => {
    event.stopPropagation();
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    reset();
    setOpen(false);
  }, [reset]);

  const isDefault = React.useMemo(
    () => taskNames.some((taskName) => task?.name === taskName),
    [task?.name]
  );

  //---------------------------------------------------------------------------
  // Delete Confirmation Form
  //---------------------------------------------------------------------------
  const { roles } = useAuth0Roles();
  const canDelete = React.useMemo(
    () => task?.id && roles.includes('Scorecard Admin'),
    [roles, task?.id]
  );
  const handleDeleteConfirmation = React.useCallback(async () => {
    if (task.id) {
      const taskUrlComponent = encodeURIComponent(task.id);
      await axios({
        method: 'DELETE',
        url: `/api/plcTasks/${taskUrlComponent}`,
      });

      // Reset and close the modal
      handleClose();

      // Update the reload state to trigger a data re-fetch
      if (setTableReload) {
        setTableReload(true);
      }
    }
  }, [handleClose, setTableReload, task]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      {task?.name ? (
        <IconButton color="primary" onClick={handleOpen} data-cy={`edit-plc-task-${task?.name}`}>
          <NoteAltIcon />
        </IconButton>
      ) : (
        <IconButton color="secondary" onClick={handleOpen} sx={{ mt: 0.5 }} data-cy="add-plc-task">
          <AddCircleIcon />
        </IconButton>
      )}
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClick={disablePropagation}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <TaskAltIcon color="secondary" sx={{ mr: 2 }} />
          {formTitle}
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          <Grid container spacing={4} sx={{ alignItems: 'center' }}>
            <Grid size={12}>
              <FormStringInput
                control={control}
                label="Task"
                name="name"
                disabled={isDefault}
                required
                rules={{
                  required: 'Please enter a name for the task',
                  maxLength: {
                    value: 128,
                    message: 'Part number should be less than 128 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormStringInput
                control={control}
                label="Department"
                name="section"
                required
                rules={{
                  required: 'Please enter a department responsible for this task',
                  maxLength: {
                    value: 128,
                    message: 'Department name should be less than 128 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 3,
              }}
            >
              <FormStringInput
                control={control}
                label="Sort Order"
                name="order"
                disabled={isDefault}
                type="number"
                required
                rules={{
                  required: 'Please enter a sort order for this item',
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 3,
              }}
            >
              <FormAutocompleteInput
                control={control}
                label="Track on KT"
                name="trackedDocument"
                options={trackedDocumentOptions}
                rules={{
                  required: 'Please select an option.',
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 2, marginRight: 'auto' }}>
            {!!task && canDelete && (
              <ConfirmAction
                actionName="Delete"
                objectName="Task"
                handleConfirmation={handleDeleteConfirmation}
                confirmKeyword="Delete"
              />
            )}
          </Box>
          <Box sx={{ m: 2 }}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box sx={{ m: 2 }}>
            <LoadingButton
              id="submitDeviceVariantButton"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              type="submit"
            >
              {task?.name ? 'Update' : 'Add'}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TaskForm;
