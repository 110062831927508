import React from 'react';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Button from '@mui/material/Button';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import PageContext from '../contexts/PageContext.jsx';

function EditButton({
  // Props
  'data-cy': dataCy,
  children,
}) {
  const { editMode, setEditMode } = React.useContext(PageContext);
  const toggle = React.useCallback(() => {
    setEditMode((prev) => !prev);
  }, [setEditMode]);

  return (
    <Button
      variant="contained"
      fullWidth
      color={editMode ? 'warning' : 'secondary'}
      onClick={toggle}
      startIcon={editMode ? <EditNoteIcon /> : <EditIcon />}
      data-cy={dataCy}
    >
      {children}
    </Button>
  );
}

export default EditButton;
