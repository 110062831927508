import React from 'react';
import { useLocation } from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import ListItem from '@mui/material/ListItem';

import { useFilter, useInterval, useLocalStorage, useSort } from '@tzmedical/react-hooks';

import taskList from '../../../shared/taskList.js';
import axios from '../../axiosClient.js';
import Alert from '../../components/common/Alert.jsx';
import PageLoading from '../../components/common/PageLoading.jsx';
import ToggleButton from '../../components/common/ToggleButton.jsx';
import EditButton from '../../components/EditButton.jsx';
import PLCForm from '../../components/PLCForm.jsx';
import DocumentContext from '../../contexts/DocumentContext.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import SearchContext from '../../contexts/SearchContext.jsx';
import PLCRow from './PLCRow.jsx';
import PLCsHeader from './PLCsHeader.jsx';

//-----------------------------------------------------------------------------
// Page configuration
//-----------------------------------------------------------------------------
const DATA_REFRESH_INTERVAL_MS = 15000;
const searchFields = {
  product: 'name',
  part: (object) => {
    if (object.parts?.length && Array.isArray(object.parts)) {
      return object.parts.reduce(
        (text, part) => `${text}, ${part.number} - ${part.description}`,
        ''
      );
    }
    return '';
  },
  engineering: 'engineeringRelease',
  launch: (object) =>
    object.isComplete && object.dateOfCompletion ? object.dateOfCompletion : object.launchDate,
  revenue: 'thirdYearRevenue',
  is: { completed: (equipment) => equipment.isComplete },
};
const sortOptions = {
  defaultSort: [
    {
      field: 'launchDate',
      reverse: false,
    },
  ],
};

//-----------------------------------------------------------------------------
function PLCPage() {
  const location = useLocation();

  const [PLCsList, setPLCsList] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [tableLoading, setTableLoading] = React.useState(true);

  //---------------------------------------------------------------------------
  // Hiding completed PLCs
  //---------------------------------------------------------------------------
  const [showCompleted, setShowCompleted] = useLocalStorage(
    `showCompleted-${location.pathname}`,
    false
  );
  const handleDisabledToggle = React.useCallback(() => {
    setShowCompleted((value) => !value);
  }, [setShowCompleted]);

  //---------------------------------------------------------------------------
  // Navbar buttons
  //---------------------------------------------------------------------------
  const { setPageButtons } = React.useContext(PageContext);
  React.useEffect(() => {
    setPageButtons(
      <span>
        <ListItem>
          <PLCForm setTableReload={setTableLoading}>Add Checklist</PLCForm>
        </ListItem>
        <ListItem>
          <EditButton>Edit Data</EditButton>
        </ListItem>
        <ListItem sx={{ mb: 1 }}>
          <ToggleButton
            data-cy="toggle-disabled"
            onClick={handleDisabledToggle}
            enabled={showCompleted}
            enabledIcon={<VisibilityIcon />}
            disabledIcon={<VisibilityOffIcon />}
            enabledMessage="Hide Completed"
            disabledMessage="Show Completed"
          />
        </ListItem>
      </span>
    );
    return () => setPageButtons(null);
  }, [setPageButtons, location, handleDisabledToggle, showCompleted]);

  //---------------------------------------------------------------------------
  // Periodically refetch the data
  //---------------------------------------------------------------------------
  const getMetrics = React.useCallback(async () => {
    try {
      if (!error) {
        const { data } = await axios({
          method: 'GET',
          url: '/api/plcs',
        });

        setPLCsList(data);
        setError(null);
        setTableLoading(false);
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    }
  }, [error]);
  useInterval(getMetrics, DATA_REFRESH_INTERVAL_MS, tableLoading && !error);

  //---------------------------------------------------------------------------
  // Search
  //---------------------------------------------------------------------------
  const { search, setSearch } = React.useContext(SearchContext);
  const formattedSearch = React.useMemo(() => {
    return showCompleted ? search : `${search} -is:completed`;
  }, [search, showCompleted]);
  const filteredPLCs = useFilter(PLCsList, formattedSearch, searchFields);
  const [sortedPLCs, handleSortSelection, sort] = useSort(filteredPLCs, sortOptions);
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch('');
      setPLCsList([]);
      setTableLoading(true);
      setError('');
    };
  }, [setSearch, location]);

  //---------------------------------------------------------------------------
  // Manual calculation of PLC tasks
  //---------------------------------------------------------------------------
  const { documents } = React.useContext(DocumentContext);
  const mergedPLCs = React.useMemo(() => {
    const withDefaultTasks = sortedPLCs.map((plc) => {
      const mergedTaskList = plc.plcTasks.concat(
        taskList.filter(
          (task) => !plc.plcTasks.some((defaultTask) => defaultTask.name === task.name)
        )
      );

      const mergedPlc = {
        ...plc,
        tasks: mergedTaskList.map((task) => {
          if (task.documentNumber) {
            const document = documents?.find((doc) => doc.id === task.documentNumber);
            const statusColor = document?.workflow_state.includes('Approved')
              ? 'success'
              : 'warning';
            return {
              ...task,
              status: document?.workflow_state,
              statusColor,
            };
          }
          let statusColor = 'warning';
          if (task.status?.includes('Completed')) {
            statusColor = 'success';
          } else if (task.status?.includes('Not Applicable')) {
            statusColor = 'disabled';
          }
          return {
            status: 'To Do',
            statusColor,
            ...(task.trackedDocument && {
              status: 'Missing Document',
              statusColor: 'error',
            }),
            ...task,
          };
        }),
      };
      mergedPlc.totalTasks = mergedPlc.tasks.length;
      mergedPlc.completedTasks = mergedPlc.tasks.reduce(
        (acc, el) => (['success', 'disabled'].includes(el.statusColor) ? acc + 1 : acc),
        0
      );

      return mergedPlc;
    });

    return withDefaultTasks;
  }, [documents, sortedPLCs]);

  if (tableLoading && !PLCsList.length) {
    return (
      <>
        <Alert message={error} setMessage={setError} level="error" />
        {!error && <PageLoading />}
      </>
    );
  }

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      <Alert message={error} setMessage={setError} level="error" />
      <PLCsHeader sort={sort} setSort={handleSortSelection} />
      {mergedPLCs.map((plc) => (
        <PLCRow key={plc.id} plc={plc} setTableReload={setTableLoading} />
      ))}
    </>
  );
}

export default PLCPage;
